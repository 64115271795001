var swiper = new Swiper(".mySwiperOurVilla", {
    slidesPerView: 2,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next-ourVilla",
      prevEl: ".swiper-button-prev-ourVilla",
    },
  });
  var swiper = new Swiper(".mySwiperRestaurant", {
    slidesPerView: 2,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next-Restaurant",
      prevEl: ".swiper-button-prev-Restaurant",
    },
  });
  var swiper = new Swiper(".mySwiperOffersnav", {
    slidesPerView: 2,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next-Offersnav",
      prevEl: ".swiper-button-prev-Offersnav",
    },
  });